import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminDashboard from "./pages/AdminDashboard";
import AddMediaCarousel from "./pages/gestionCarousels/AddMediaCarousel";
import UpdateMediaCarousel from "./pages/gestionCarousels/UpdateMediaCarousel";
import AddMedia from "./pages/gestionMedias/AddMedia";
import UpdateMedia from "./pages/gestionMedias/UpdateMedia";
import AddMediaPopup from "./pages/gestionPopups/AddMediaPopup";
import UpdateMediaPopup from "./pages/gestionPopups/UpdateMediaPopup";
import Login from "./pages/Login";
import MediaCarousel from "./pages/MediaCarousel";
import MediaPopup from "./pages/MediaPopup";
import Medias from "./pages/Medias";
import NewsLetter from "./pages/NewsLetter";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/medias" element={<Medias />} />
        <Route path="/medias/ajouter" element={<AddMedia />} />
        <Route path="/medias/modifier/:id" element={<UpdateMedia />} />
        <Route path="/medias/carousel" element={<MediaCarousel />} />
        <Route path="/medias/carousel/ajouter" element={<AddMediaCarousel />} />
        <Route
          path="/medias/carousel/modifier/:id"
          element={<UpdateMediaCarousel />}
        />
        <Route path="/medias/popup" element={<MediaPopup />} />
        <Route path="/medias/popup/ajouter" element={<AddMediaPopup />} />
        <Route
          path="/medias/popup/modifier/:id"
          element={<UpdateMediaPopup />}
        />
        <Route path="/newsletter" element={<NewsLetter />} />
        <Route path="/" element={<AdminDashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
