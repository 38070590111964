import axios from "axios";
 
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import NavBar from "../../components/NavBar";

const AddMediaCarousel = () => {
  const [content, setContent] = useState("");
  const [id, setId] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    // Fonction pour récupérer tous les médias
    const fetchMedias = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/media`);
        const data = await response.data.medias;
        setMedias(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des médias:", error);
      }
    };

    fetchMedias();
  }, []);

  const handleContentChange = (value) => {
    setContent(value);
    setCharCount(value.length);
  };

  const handleAddMedia = async (e) => {
    e.preventDefault();

    if(charCount > 1020) {
      Swal.fire({
        icon: "error",
        title: "Erreur !",
        text: "Le contenu alternatif ne doit pas dépasser 1020 caractères",
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/media/popup`, {
        id,
        content,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Media ajouté au carousel avec succès",
        });
        setContent("");
        setId("");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur !",
        text: error.response?.data?.message || "Une erreur est survenue",
      });
    }
  };

  return (
    <>
      <NavBar page={"Medias"} />
      <div className="bg-gray-900 h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500 text-sm mb-0">
                {" "}
                /Medias/Popup/Ajouter
              </span>
              <h1 className="mt-0 text-2xl font-semibold">
                Ajouter un Media au Popup
              </h1>
            </div>
          </div>
          <form onSubmit={handleAddMedia} className="mt-6">
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2">
                Media ID
              </label>
              <select
                value={id}
                onChange={(e) => setId(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Sélectionnez un media</option>
                {medias.map((media) => (
                  <option key={media.id} value={media.id}>
                    {media.id} - {media.pseudo}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2">
                Contenu alternatif
              </label>
              <ReactQuill
                value={content}
                onChange={handleContentChange}
                placeholder="Entrez le contenu alternatif"
                theme="snow"
              />
              <p className="text-gray-300 text-sm mt-2">
                {charCount} / 1020 caractères
              </p>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddMediaCarousel;
