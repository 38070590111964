import axios from "axios";
 
import { default as React, useEffect, useState } from "react";
import { FaCheck, FaExternalLinkAlt, FaInfo } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import {
  MdDelete,
  MdModeEditOutline,
  MdOutlineFirstPage,
  MdOutlineLastPage,
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const MediaCarouselTab = () => {
  const navigate = useNavigate();
  const [medias, setMedias] = useState([]);
  const [rowsToShow, setRowsToShow] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getMedias = async () => {
      console.log("Fetching medias...");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/media/carousel`);
      const data = await response.data.medias;
      setMedias(data);
    };
    getMedias();
  }, []);

  const handleDelete = async (id) => {
    try {
      // Confirmer la suppression avec un message de confirmation
      const result = await Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Oui, supprimer !",
      });

      if (result.isConfirmed) {
        // Suppression du média via l'API
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/media/carousel/delete/${id}`
        );
        if (response.status === 200) {
          // Affiche un message de succès
          Swal.fire({
            icon: "success",
            title: "Media supprimé du carousel avec succès",
          });

          // Recharger la liste des médias après la suppression
          setMedias((prevMedias) =>
            prevMedias.filter((media) => media.id !== id)
          );
        }
      }
    } catch (error) {
      // Gestion des erreurs
      Swal.fire({
        icon: "error",
        title: "Erreur lors de la suppression",
        text: error.response?.data?.message || error.message,
      });
    }
  };

  const handleRowsChange = (e) => {
    setRowsToShow(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when rowsToShow changes
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Filtrez les abonnés en fonction de la valeur de recherche
  const filteredMedias = medias.filter((media) =>
    media.media.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredMedias.length / rowsToShow);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const startIndex = (currentPage - 1) * rowsToShow;
  const currentMedias = filteredMedias.slice(
    startIndex,
    startIndex + rowsToShow
  );

  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Rechercher par titre"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 rounded text-white bg-gray-800 w-full"
          />
        </div>
        <div className="text-lg ml-2 mb-0">
          <span>{filteredMedias.length}</span> lignes retournées
        </div>

        <div className="mb-4">
          <label htmlFor="rowsToShow" className="mr-2">
            Ligne par page:
          </label>
          <input
            type="number"
            id="rowsToShow"
            value={rowsToShow}
            onChange={handleRowsChange}
            className="p-1 rounded text-white bg-gray-800"
            min={1}
          />
        </div>
      </div>

      <table className="min-w-full bg-gray-800">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-700">Id</th>
            <th className="py-2 px-4 border-b border-gray-700">Pseudo</th>
            <th className="py-2 px-4 border-b border-gray-700">Titre</th>
            <th className="py-2 px-4 border-b border-gray-700">I/V</th>
            <th className="py-2 px-4 border-b border-gray-700">NewsLetter</th>
            <th className="py-2 px-4 border-b border-gray-700">Lien</th>
            <th className="py-2 px-4 border-b border-gray-700">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentMedias.map((media) => (
            <tr key={media.id}>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {media.id}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {media.media.pseudo}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {media.media.title}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {media.media.type[0].toUpperCase()}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {media.media.usedForNewsLetter ? (
                  <FaCheck className="inline" />
                ) : (
                  <ImCross className="inline" />
                )}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                <a
                  href={media.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-400 hover:underline"
                >
                  <FaExternalLinkAlt className="inline" />
                </a>
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                <button
                  className="bg-redPrimary hover:bg-redSecondary text-white text-xl font-bold py-1 px-2 hover:cursor-pointer rounded"
                  onClick={() => handleDelete(media.id)}
                >
                  <MdDelete />
                </button>
                <button
                  className="bg-blue-600 mx-2 hover:bg-blue-800 text-white text-xl font-bold py-1 px-2 hover:cursor-pointer rounded"
                  onClick={() =>
                    navigate(`/medias/carousel/modifier/${media.id}`)
                  }
                >
                  <MdModeEditOutline />
                </button>
                <button className="bg-slate-800 hover:bg-slate-950 text-dark text-xl font-bold py-1 px-2 hover:cursor-pointer rounded">
                  <FaInfo />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex space-x-2 justify-center mt-4">
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          className="bg-gray-700 text-white py-1 px-2 rounded disabled:opacity-50"
        >
          <MdOutlineFirstPage />
        </button>
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-gray-700 text-white py-1 px-2 rounded disabled:opacity-50"
        >
          <MdOutlineNavigateBefore />
        </button>
        <span className="text-white">
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-gray-700 text-white py-1 px-2 rounded disabled:opacity-50"
        >
          <MdOutlineNavigateNext />
        </button>
        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          className="bg-gray-700 text-white py-1 px-2 rounded disabled:opacity-50"
        >
          <MdOutlineLastPage />
        </button>
      </div>
    </>
  );
};

export default MediaCarouselTab;
