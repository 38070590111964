import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import VisitsChart from "../components/VisitsChart";
import axios from "axios";
 

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    nbrMedias : 0,
    nbrMediaCarousels : 0,
    nbrMediaPopups : 0,
    nbrSubscribers: 0,
    nbrVisits: 0,
  });

  useEffect(() => {
    const fetchStats = async() => {
      try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/stats`);
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching stats data:", error);
      }
    }
    fetchStats();
  }, []);

  return (
    <>
      <NavBar page={"Dashboard"} />
      <div className="bg-gray-900 h-screen pt-4 p-2 md:p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <span className="text-gray-500 text-sm mb-0"> /Dashboard</span>
          <h1 className="mt-0 text-2xl font-semibold">Admin Dashboard</h1>
          {/* adding cards for statistics */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
            <Link to="/medias" className="bg-gray-800 p-5 flex items-center rounded-lg">
              <div className="flex items-center justify-center w-16 h-16 rounded-full text-redSecondary bg-red-200">
                <p className="text-2xl font-semibold">{stats.nbrMedias}</p>
              </div>
              <div className="pl-2">
                <h2 className="text-lg font-semibold">Medias</h2>
                <p className="text-sm">Insérés</p>
              </div>
            </Link>
            <Link to="/medias/carousel" className="bg-gray-800 p-5 flex items-center rounded-lg">
              <div className="flex items-center justify-center w-16 h-16 rounded-full text-redSecondary bg-red-200">
                <p className="text-2xl font-semibold">{stats.nbrMediaCarousels}</p>
              </div>
              <div className="pl-2">
                <h2 className="text-lg font-semibold">Medias</h2>
                <p className="text-sm">Dans le Carousel</p>
              </div>
            </Link>
            <Link to="/medias/popup" className="bg-gray-800 p-5 flex items-center rounded-lg">
              <div className="flex items-center justify-center w-16 h-16 rounded-full text-redSecondary bg-red-200">
                <p className="text-2xl font-semibold">{stats.nbrMediaPopups}</p>
              </div>
              <div className="pl-2">
                <h2 className="text-lg font-semibold">Medias</h2>
                <p className="text-sm">Dans le Popup</p>
              </div>
            </Link>
          </div>
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 px-4">
            <a href="#visits-chart" className="bg-gray-800 p-5 flex items-center rounded-lg">
              <div className="flex items-center justify-center w-16 h-16 rounded-full text-redSecondary bg-red-200">
                <p className="text-2xl font-semibold">{stats.nbrVisits}</p>
              </div>
              <div className="pl-2">
                <h2 className="text-lg font-semibold">Visits</h2>
                <p className="text-sm">Cette Dernière Semaine</p>
              </div>
            </a>
            <Link to="/newsletter" className="bg-gray-800 p-5 flex items-center rounded-lg">
              <div className="flex items-center justify-center w-16 h-16 rounded-full text-redSecondary bg-red-200">
                <p className="text-2xl font-semibold">{stats.nbrSubscribers}</p>
              </div>
              <div className="pl-2">
                <h2 className="text-lg font-semibold">Abonnés</h2>
                <p className="text-sm">à la Newsletter</p>
              </div>
            </Link>
          </div>
          <div id="visits-chart" className="p-4">
            <VisitsChart />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
