import React from "react";
import { useNavigate } from "react-router-dom";
import MediaCarouselTab from "../components/MediaCarouselTab";
import NavBar from "../components/NavBar";

const MediaCarousel = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar page={"Medias"} />
      <div className="bg-gray-900 h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500 text-sm mb-0">
                {" "}
                /Medias/Carousel
              </span>
              <h1 className="mt-0 text-2xl font-semibold">
                Medias dans le Carousel
              </h1>
            </div>
            <button
              className="bg-green-600 hover:bg-green-800 text-white text-lg font-semibold py-1 px-2 hover:cursor-pointer rounded"
              onClick={() => navigate("/medias/carousel/ajouter")}
            >
              Ajouter un media au carousel
            </button>
          </div>
          <MediaCarouselTab />
        </div>
      </div>
    </>
  );
};

export default MediaCarousel;
