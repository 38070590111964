import React, { useEffect, useState } from "react";
import axios from "axios";
 
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const VisitsChart = () => {
  const [chartData, setChartData] = useState({});
  const [range, setRange] = useState("week");

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "category",
        ticks: {
          color: "white",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
      y: {
        ticks: {
          color: "white",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "white",
        },
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/visit`);
        const data = res.data;
        const dates = [];
        const visits = [];
        let updatedData;

        if (range === "week") {
          updatedData = data.slice(data.length - 7);
        } else {
          updatedData = data.slice(data.length - 30);
        }

        updatedData.forEach((visit) => {
          dates.push(visit.date.split("T")[0]);
          visits.push(visit.visits);
        });

        setChartData({
          labels: dates,
          datasets: [
            {
              label: "Visits",
              data: visits,
              backgroundColor: "#E3311D",
              borderColor: "#782221",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching visits data:", error);
      }
    };

    fetchData();
  }, [range]);

  return (
    <div className="bg-gray-800 p-5 rounded-lg">
      <div className="flex justify-between mb-4">
        <h2 className="text-lg font-semibold text-white">Nombre de Visites </h2>
        <select
          value={range}
          onChange={(e) => setRange(e.target.value)}
          className="bg-gray-300 text-black p-1 rounded-md"
        >
          <option value="week">Dernière semaine</option>
          <option value="month">Dernier Mois</option>
        </select>
      </div>
      {chartData.labels && chartData.labels.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <p className="text-white">No data available for the selected range.</p>
      )}
    </div>
  );
};

export default VisitsChart;
