import axios from "axios";
 
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import NavBar from "../../components/NavBar";

const UpdateMediaCarousel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mediaCarousel, setMediaCarousel] = useState({
    media: "",
    url: "",
  });
  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchMediaCarousel = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/media/carousel/one/${id}`
        );
        setMediaCarousel(response.data.mediaCarousel);
        setUrl(response.data.mediaCarousel.url);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Erreur !",
          text: err.response?.data?.message || "Une erreur est survenue",
        });
      }
    };

    fetchMediaCarousel();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Vous êtes sur le point de modifier un Media Carousel",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Oui, modifier",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/media/carousel/update/${id}`,
            { url },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Media Carousel modifié avec succès",
            });
            navigate("/medias/carousel");
          }
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Erreur !",
        text: err.response?.data?.message || "Une erreur est survenue",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <NavBar page={"Medias"} />
      <div className="bg-gray-900 h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500 text-sm mb-0">
                {" "}
                /Medias/Carousel/Modifier
              </span>
              <h1 className="mt-0 text-2xl font-semibold">
                Modifier un Media Carousel
              </h1>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-3">
              <div className="mt-4">
                <label className="block text-gray-300 text-sm font-bold mb-2">
                  Media
                </label>
                <select
                  id="media-id"
                  name="media"
                  value={mediaCarousel.media.id}
                  disabled
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                >
                  <option value="">
                    {mediaCarousel.media.pseudo} - {mediaCarousel.media.title}
                  </option>
                </select>
              </div>
              <div className="mt-4">
                <label className="block text-gray-300 text-sm font-bold mb-2">
                  L&apos;URL de la publication
                </label>
                <input
                  type="text"
                  name="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-redPrimary hover:bg-redSecondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? <ClipLoader color="#fff" /> : "Modifier"}
              </button>
              <button
                onClick={() => navigate("/medias/carousel")}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white border-md-1 border-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-4"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateMediaCarousel;
