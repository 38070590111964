import axios from "axios";
 
import React, { useState } from "react";
import Swal from "sweetalert2";
import NavBar from "../../components/NavBar";

const AddMedia = () => {
  const [pseudo, setPseudo] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("image");
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pseudo", pseudo);
    formData.append("title", title);
    formData.append("body", body);
    formData.append("type", type);
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/media`, // Ensure the correct URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Media ajouté avec succès",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setPseudo("");
        setTitle("");
        setBody("");
        setType("image");
        setFile(null);
      }
    } catch (error) {
      console.error("Error adding media:", error);
      Swal.fire({
        title: "Erreur!",
        text: error.response
          ? error.response.data.message
          : "Une erreur est survenue",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <NavBar page={"Medias"} />
      <div className="bg-gray-900 h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500 text-sm mb-0">
                {" "}
                /Medias/Ajouter
              </span>
              <h1 className="mt-0 text-2xl font-semibold">Ajouter un Media</h1>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-3">
              <div className="flex-none sm:flex gap-3 space-y-3 sm:space-y-0">
                <div className="sm:flex-1">
                  <label htmlFor="media-pseudo" className="sr-only">
                    Pseudo
                  </label>
                  <input
                    id="media-pseudo"
                    name="pseudo"
                    type="text"
                    value={pseudo}
                    onChange={(e) => setPseudo(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-tl-md rounded-tr-md sm:rounded-tr-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Pseudo"
                  />
                </div>
                <div className="sm:flex-1">
                  <label htmlFor="media-title" className="sr-only">
                    Title
                  </label>
                  <input
                    id="media-title"
                    name="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="appearance-none rounded-tr-none sm:rounded-tr-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="media-body" className="sr-only">
                  Description
                </label>
                <textarea
                  id="media-body"
                  name="body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Description"
                />
              </div>
              <div className="flex gap-3 mt-4">
                <div className="flex-1 relative">
                  <label htmlFor="media-type" className="sr-only">
                    Type
                  </label>
                  <select
                    id="media-type"
                    name="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  >
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7 10l5 5 5-5H7z" />
                    </svg>
                  </div>
                </div>
                <div className="flex-1">
                  <label htmlFor="media-file" className="sr-only">
                    File
                  </label>
                  <input
                    id="media-file"
                    name="file"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                    className="appearance-none rounded-br-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-100 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Media
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddMedia;
