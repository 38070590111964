import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";

const NotFound = () => {
  return (
    <>
      <NavBar page={"404"} />
      <div className="flex items-center justify-center h-screen bg-blackPrimary text-slate-100">
        <div className="text-center">
          <h1 className="text-8xl font-bold mb-4">404</h1>
          <p className="text-2xl mb-8">Page non trouvée</p>
          <Link
            to="/"
            className="bg-redSecondary hover:bg-redPrimary text-white font-bold py-2 px-4 rounded"
          >
            Retour à l&apos;accueil
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
