import axios from "axios";
 
import React, { useState } from "react";
import Swal from "sweetalert2";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, {
        username,
        password,
      });
      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Connexion réussie",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem("token", response.data.token);
        setUsername("");
        setPassword("");
        window.location.href = "/";
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.response.data.message,
      });
    }
  };

  return (
    <div className="flex mt-2 bg-black min-h-full flex-1 flex-col md:flex-row justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm justify-start">
        <img
          alt="DeltaSign logo"
          src="/logo-primary.svg"
          className="mx-auto h-full w-auto"
        />
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-xl/9 font-semibold tracking-tight text-slate-50 mb-3">
          Se connecter en tant qu&apos;Administrateur
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="username"
              className="block text-sm/6 font-medium text-slate-50"
            >
              Nom d&apos;utilisateur
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                placeholder="Nom d'utilisateur"
                required
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-2 shadow-sm placeholder:text-gray-400 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm/6 font-medium text-slate-50"
            >
              Mot de passe
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="********"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-2 shadow-sm placeholder:text-gray-400 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-redSecondary px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Se Connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
