import axios from "axios";
 
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import NavBar from "../../components/NavBar";

const UpdateMedia = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState({
    pseudo: "",
    title: "",
    body: "",
    type: "image",
    file: null,
  });

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/media/one/${id}`
        );
        setMedia(response.data.media);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Erreur !",
          text: err.response?.data?.message || "Une erreur est survenue",
        });
      }
    };

    fetchMedia();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMedia((prevMedia) => ({
      ...prevMedia,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setMedia((prevMedia) => ({
      ...prevMedia,
      file: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("pseudo", media.pseudo);
    formData.append("title", media.title);
    formData.append("body", media.body);
    formData.append("type", media.type);
    formData.append("file", media.file);

    try {
      if (!media.file) {
        const result = await Swal.fire({
          title: "Champ Media vide",
          text: "Voulez-vous vraiment garder l'ancien media ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Garder et appliquer les modification !",
        });
        if (result.isConfirmed) {
          console.log("Sending request to /media/update/withoutMedia/:id");
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/media/update/withoutMedia/${id}`,
            {
              pseudo: media.pseudo,
              title: media.title,
              body: media.body,
              type: media.type,
            }
          );
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Media modifié avec succès",
            });
            navigate("/medias");
          }
        }
      } else {
        const result = await Swal.fire({
          title: "Voulez-vous vraiment modifier ce media ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Modifier",
        });
        if (result.isConfirmed) {
          console.log("Sending request to /media/update/withMedia/:id");
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/media/update/withMedia/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Media modifié avec succès",
            });
            navigate("/medias");
          }
        }
      }
    } catch (err) {
      console.error("Error updating media:", err);
      Swal.fire({
        icon: "error",
        title: "Erreur !",
        text: err.response?.data?.message || "Une erreur est survenue",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <NavBar page={"Medias"} />
      <div className="bg-gray-900 h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500 text-sm mb-0">
                {" "}
                /Medias/Modifier
              </span>
              <h1 className="mt-0 text-2xl font-semibold">Modifier un Media</h1>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-3">
              <div className="flex-none sm:flex gap-3 space-y-3 sm:space-y-0">
                <div className="sm:flex-1">
                  <label htmlFor="media-pseudo" className="sr-only">
                    Pseudo
                  </label>
                  <input
                    id="media-pseudo"
                    name="pseudo"
                    type="text"
                    value={media.pseudo}
                    onChange={handleChange}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-tl-md rounded-tr-md sm:rounded-tr-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Pseudo"
                  />
                </div>
                <div className="sm:flex-1">
                  <label htmlFor="media-title" className="sr-only">
                    Title
                  </label>
                  <input
                    id="media-title"
                    name="title"
                    type="text"
                    value={media.title}
                    onChange={handleChange}
                    required
                    className="appearance-none rounded-tr-none sm:rounded-tr-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="media-body" className="sr-only">
                  Description
                </label>
                <textarea
                  id="media-body"
                  name="body"
                  value={media.body}
                  onChange={handleChange}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Description"
                />
              </div>
              <div className="flex gap-3 mt-4">
                <div className="flex-1 relative">
                  <label htmlFor="media-type" className="sr-only">
                    Type
                  </label>
                  <select
                    id="media-type"
                    name="type"
                    value={media.type}
                    onChange={handleChange}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  >
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7 10l5 5 5-5H7z" />
                    </svg>
                  </div>
                </div>
                <div className="flex-1">
                  <label htmlFor="media-file" className="sr-only">
                    File
                  </label>
                  <input
                    id="media-file"
                    name="file"
                    type="file"
                    onChange={handleFileChange}
                    className="appearance-none rounded-br-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-100 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-redPrimary hover:bg-redSecondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? <ClipLoader color="#fff" /> : "Modifier"}
              </button>
              <button
                onClick={() => navigate("/medias")}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white border-md-1 border-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-4"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateMedia;
