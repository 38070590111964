import { default as React} from "react";
import NavBar from "../components/NavBar";
import SubscribersTab from "../components/SubscribersTab";

const NewsLetter = () => {
  return (
    <>
      <NavBar page={"NewsLetter"} />
      <div className="bg-gray-900 min-h-screen p-10">
        <div className="container min-h-96 p-5 rounded-lg bg-blackPrimary mt-14 mx-auto text-slate-50 ">
          <span className="text-gray-500 text-sm mb-0"> /NewsLetter</span>
          <h1 className="mt-0 text-2xl font-semibold">NewsLetter</h1>

          <h3 className="mt-2 text-xl font-semibold">
            Liste des abonnés à la newsletter
          </h3>
          <SubscribersTab/>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
